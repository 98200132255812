import React from 'react';
import SectionItem from '../../../../assets/themeComponents/components/SectionItem'
import Section from '../../../../assets/themeComponents/components/Section'
import SelectForm from '../../../../assets/themeComponents/components/SelectForm'
import SwitchBox from '../../../../assets/themeComponents/components/SwitchBox'
import InputRadioGroup from '../../../../assets/themeComponents/components/InputRadioGroup'

const countryIdToCode = {
  101: 'UY',
  102: 'AR',
  105: 'PE',
};

const buildRangeName = ({ id, country_id, school_category, from, to }) => {
  const finalRange = { id };
  let rangeName = `${school_category === 'preschool' ? 'Jardines' : 'Colegios'} ${countryIdToCode[country_id]} - `;
  if (to === 0) rangeName += 'Gratuito';
  else if (typeof to === 'number') rangeName += `Hasta $${to.toLocaleString('es-UY')}`;
  else if (typeof from === 'number') rangeName += `Más de $${from.toLocaleString('es-UY')}`;
  finalRange.name = rangeName;
  return finalRange;
};

const buildRanges = (rangesFromDB) => {
  const built = rangesFromDB.map(buildRangeName);
  console.log('RANGES', rangesFromDB, built);
  return built
};

const AditionalInfo = (({ costs, rangePrices, religious }) => {
  return (
  <Section sectionLabel="Información adicional">
    <SectionItem itemLabel="Costo educativo *">
      <InputRadioGroup
        options={costs.costs.slice(1)}
        value={costs.chosenCost}
        setValue={costs.setChosenCost}
        />
    </SectionItem>
    <SectionItem itemLabel='Rango de precio'>
      <SelectForm
        options={buildRanges(rangePrices.rangePrices)}
        optionDetails={{ id: 'id', name: 'name' }}
        value={rangePrices.chosenRangePrice}
        setValue={rangePrices.setChosenRangePrice}
      />
    </SectionItem>
    <SectionItem itemLabel="Religion *">
      <SwitchBox
        setValue={religious.setReligious}
        enableLabel="Si"
        disableLabel="Laico"
        name="religion"
        value={religious.religious}
        />
    </SectionItem>
    { religious.religious && 
      <SectionItem >
      <SelectForm
        model="religion"
        options={religious.religions.slice(2)}
        value={religious.chosenReligion}
        setValue={religious.setChosenReligion}
        optionDetails={{ id: 'id', name: 'name' }}
        label="Agrega religión ( Si no lo hay, seleccione Laico)"
        required={true}
        disabled={!religious.religious}
        />
    </SectionItem>
      }
    {/* <SectionItem itemLabel="Adaptados para alumnos com necessidades especiales">
      <SwitchBox
        setValue={specialNeeds.setSpecialNeeds}
        enableLabel="Si"
        disableLabel="no"
        name="special_needs"
        value={specialNeeds.specialNeeds}
        />
    </SectionItem> */}
  </Section>
)
});

export default AditionalInfo